import { scrollElementIntoView, waitForElement } from '~/utils/dom';

const listenAndScroll = async () => {
	scrollElementIntoView(await waitForElement('.input-error'));
};

export const init = () => {
	document.querySelectorAll('form').forEach(form => {
		form.addEventListener('submit', listenAndScroll);
	});
};

init();
